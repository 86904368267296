import styled, { css } from "styled-components";
import { responsive } from "theme/reponsive";

export const SearchWrapper = styled.div<{ isLeft?: boolean }>`
   display: flex;
   justify-content: flex-end;

   .search-input {
      display: flex;
      align-items: center;
      width: 30rem;

      p {
         margin-right: 10px;
      }
   }

   ${({ isLeft }) =>
      isLeft &&
      css`
         justify-content: flex-start;
      `}
`;

export const LandingSearchWrapper = styled.div`
   display: flex;
   justify-content: center;

   .search-input {
      width: 60rem;
   }
`;

export const FilterWrapper = styled.div`
   display: flex;
   justify-content: center;
   width: 100%;
   .landing-search-btn {
      display: flex;
      justify-content: center;
      margin-top: 20px;
   }
   .center {
      margin-top: 20px;
   }
   .find-school {
      border-radius: 8px;
      width: 400px;
   }
   ${responsive("$small")`

      .main-container {
         width:100%;
      }

      .find-school {
         width: 100%;
      }
      .landing-search-btn {
         margin-top:15px;
         margin-bottom:50px;
      }
   `}
`;

export const FilterContainer = styled.div`
   width: 60rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 20px;
   .start {
      width: 48%;
   }
   .end {
      width: 48%;
   }

   ${responsive("$small")`
      width:100%;
      margin-top: 0px !important;
      flex-direction: column;
      .start {
         width: 100%;
         margin-top:15px;
      }
      .end {
         width: 100%;
         margin-top:15px;

      }
   `}
`;
