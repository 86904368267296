import React, { useState } from "react";
import {
   CenterInputContainer,
   NewsInput,
   NewsInputContainer,
   NewsLetterContainer,
   NewsLetterMain,
   SubmitButton,
} from "container/advisory/advisory.style";
import { useNotify } from "hooks";
import { subscribeNews } from "services/news-letter";

const NewsLetter = () => {
   const { notify } = useNotify();
   const [email, setEmail] = useState<string>("");
   const [isLoading, setIsLoading] = useState<boolean>(false);

   async function subscribeToNewsLetter() {
      try {
         setIsLoading(true);
         const response: any = await subscribeNews({ email: email });
         setIsLoading(false);
         notify({
            message: response.result.message || response.result.custom_message,
            variant: "success",
         });
         setEmail("");
      } catch (error: any) {
         notify({
            message: error.result.message || error.result.custom_message,
            variant: "error",
         });
         setIsLoading(false);
      }
   }

   return (
      <NewsLetterContainer>
         <NewsLetterMain>
            <h3>Join our Newsletter</h3>
            <p>
               Enter your email address below to subscribe for our tailored <br /> newsletters that
               get delivered straight to your email inbox regularly!
            </p>

            <CenterInputContainer>
               <NewsInputContainer>
                  <NewsInput
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     type="text"
                     placeholder="Enter your email"
                  />
                  <SubmitButton
                     onClick={subscribeToNewsLetter}
                     disabled={email === "" || isLoading}
                  >
                     Subscribe
                  </SubmitButton>
               </NewsInputContainer>
            </CenterInputContainer>
         </NewsLetterMain>
      </NewsLetterContainer>
   );
};

export default NewsLetter;
