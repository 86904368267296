import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Slider, { Settings } from "react-slick";
import { homePageAdverts } from "services/adverts";
import { MainAdvertContainer, SingleAvert } from "./landing-page.style";
export const HomePageAdvert = () => {
   const [adverts, setAdverts] = useState<any>([]);

   const { data } = useQuery<any>("home-page-adverts", homePageAdverts as any);
   useEffect(() => {
      setAdverts(data?.data?.data);
   }, [data]);

   const settings: Settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 2,
      speed: 1000,
      autoplaySpeed: 4000,
      arrows: false,
      initialSlide: 0,
      infinite: true,
      responsive: [
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: false,
               centerMode: true,
               initialSlide: 3,
            },
         },
         {
            breakpoint: 1500,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               arrows: false,
            },
         },
         {
            breakpoint: 500 || 400 || 300,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            },
         },
      ],
   };

   const filter = {
      country: "nigeria",
      page: "home",
   };

   const testAd = adverts?.filter(function (item) {
      for (let key in filter) {
         if (item[key] === undefined || !item[key].includes(filter[key]) ) return false;
      }
      return true;
   });


   return (
      <>
         <MainAdvertContainer>
            <Slider draggable {...settings} className="card-slider">
               {testAd?.map((ads, idx) => (
                  <Adverts key={idx} data={ads} />
               ))}
            </Slider>
         </MainAdvertContainer>
      </>
   );
};

const Adverts = (data: any) => {
   return (
      <>
         <a href={data?.data?.url} target="_blank" rel="noopener noreferrer">
            <SingleAvert url={`${data?.data?.image}`} />
         </a>
      </>
   );
};
