import { useState, useEffect } from "react";
import router from "next/router";
import { useQuery } from "react-query";
import { getSchoolsStats } from "services/school";
import { NewTextHeadingOne } from "styles/text-heading-one";
import { Div } from "styles/typography";
import { AdvertBox, AdvertContainer } from "./landing-page.style";

export const UpdatedAdvertBanners = ({ headingText }) => {
   const [schoolStats, setSchoolStats] = useState<any>({});

   const { data } = useQuery<any>("school-stats", () => getSchoolsStats({ country: "nigeria" }));
   useEffect(() => {
      setSchoolStats(data?.data?.data);
   }, [data]);

   function routeWithSearchParams(param) {
      router.push({
         pathname: "/school/search",
         query: param,
      });
   }

   const schoolAdverts = [
      {
         title: "Schools in Lagos",
         urlColor: "#000000",
         state: "lagos",
         country: "nigeria",
         data: schoolStats?.lagos,
         link: "/school/search?state=lagos&country=nigeria",
      },
      {
         title: "Schools in Port Harcourt",
         urlColor: "#264FFA",
         state: "rivers",
         country: "nigeria",
         data: schoolStats?.online,
         link: "/school/search?state=rivers&country=nigeria",
      },
      {
         title: "Top Universities Home & Abroad",
         urlColor: "#46D3C2",
         state: "rivers",
         country: "nigeria",
         data: schoolStats?.studyAbroad,
         link: "/study-abroad",
      },
      {
         title: "Schools in Abuja",
         urlColor: "#6F55FF",
         state: "abuja",
         country: "nigeria",
         data: schoolStats?.abuja,
         link: "/school/search?search=abuja&state=abuja&country=nigeria",
      },

      {
         title: "Other Cities",
         urlColor: "#FFC431",
         country: "nigeria",
         data: schoolStats?.summerSchool,
         link: "/school/search?country=nigeria",
      },
      {
         title: "Top Summer Camps 2024",
         urlColor: "#DA6C08",
         country: "nigeria",
         data: schoolStats?.online,
         link: "/summer-camp",
      },
   ];

   return (
      <div style={{ marginTop: "100px" }}>
         <Div display="flex" alignItems="center" justifyContent="center">
            <NewTextHeadingOne>{headingText}</NewTextHeadingOne>
         </Div>
         <AdvertContainer>
            {schoolAdverts?.map((sch, idx) => (
               <AdvertBox
                  key={idx}
                  urlColor={sch?.urlColor}
                  onClick={() =>
                     routeWithSearchParams({
                        state: sch?.state,
                        country: sch?.country,
                     })
                  }
                  href={sch?.link}
               >
                  <div className="text-content">
                     <h4>{sch?.title}</h4>
                     <h6>{`${sch?.data} schools.`}</h6>
                  </div>
               </AdvertBox>
            ))}
         </AdvertContainer>
      </div>
   );
};
