import { BsArrowRight } from "react-icons/bs";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";
import styled from "styled-components";
import { responsive } from "theme/reponsive";

export const TopWrapper = styled.div<{ url?: string }>`
   background-image: linear-gradient(#1c1c3910, #0c0c0ca6), url(${({ url }) => url});
   height: 337px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   padding: 30px;
   flex-direction: column;
   justify-content: center;

   ${responsive("$xsmall")`
   height: 40vh;
   padding: 10px;
   font-size: 50px;
`}

   h1 {
      position: absolute;
      color: white;
      z-index: 2;
      font-size: clamp(22px, 5.5vw, 60px);
      font-weight: 400;
   }
`;

export const BannerHolder = styled.div<{ url?: string }>`
   height: 300px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;

   div {
      height: 100%;
      width: 100%;

      img {
         object-fit: cover;
      }
   }
`;

export const AdvisoryWrapper = styled.div`
   display: grid;
   gap: 3rem;
   margin: 2rem 0;

   ${responsive("$xsmall")`
`}

   @media screen and (max-width: 990px) {
      display: block;
      margin: 1rem;
   }
`;

export const PersonnelSection = styled.div`
   grid-column: 1/4;
`;

export const ImageHolder = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   width: 170px;
   div {
      width: 170px;

      margin-top: 10px;
   }
   img {
      height: 100%;
      max-width: 100%;
   }

   @media screen and (max-width: 570px) {
      transform: scale(0.7);
      margin-bottom: -2rem;
      text-align: center;

      div {
         border-radius: 10px;
         height: 100%;
      }

      img {
         height: 100px;
         width: 100px;
      }
   }
`;

export const PersonnelWrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

export const PersonHolder = styled.div`
   padding: 10px 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 2rem;
   height: auto;
   width: 100%;
   background-color: ${({ theme }) => theme.colors?.white};
   box-shadow: 0 3px 25px rgb(0 0 0 / 4%);
   border-radius: 5px;

   @media screen and (max-width: 570px) {
      display: block;
   }
`;

export const ContentWrapper = styled.div`
   padding: 1.5rem 2rem;
   width: 100%;
   display: flex;
   flex-direction: column;

   h4 {
      color: #3578e5;
      font-size: 2.2rem;
      font-weight: 500;
      text-transform: capitalize;
   }

   h5 {
      color: #b9b5b5;
      font-weight: 500;
      margin: 10px 0px;
      font-size: 1.6rem;
   }

   // p {
   //    color: #6b6b6b;
   //    font-size: 1.6rem;
   //    margin: 5px 0px;
   //    display: -webkit-box;
   //    -webkit-line-clamp: 5;
   //    -webkit-box-orient: vertical;
   //    overflow: hidden;
   // }

   button {
      margin-top: 13px;
      width: 100px;
      border: none;
      text-align: start;
      color: #3578e5;
      font-size: 12px;
      padding: 0px;
      font-weight: 500;
      cursor: pointer;
   }

   @media screen and (max-width: 570px) {
      border-radius: 10px;
   }
`;

export const Arrow = styled(BsArrowRight)`
   font-size: 14px;
   transform: translateY(3px);
`;

export const ArrowRight = styled(GrCaretNext)`
   font-size: 18px;
   transform: translateY(3px);
   color: #fff;
   @media screen and (max-width: 768px) {
      font-size: 12px;
   }
`;

export const ArrowLeft = styled(GrCaretPrevious)`
   font-size: 18px;
   transform: translateY(3px);
   color: #fff;
   @media screen and (max-width: 768px) {
      font-size: 12px;
   }
`;

export const AdsSection = styled.div`
   grid-column: 4/5;
   max-width: 300px;

   @media screen and (max-width: 990px) {
      width: 100%;
      max-width: 900px;
   }
`;

export const AdsTop = styled.div`
   padding-bottom: 2rem;
   border: none;

   .button {
      margin-top: 13px;
      width: 100px;
      border: none;
      text-align: start;
      color: #3578e5;
      font-size: 12px;
      padding: 0px;
      font-weight: 500;
      cursor: pointer;
      background: none;
   }
`;

export const AdsAndBlog = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const AdsHolder = styled.div`
   div {
      border-radius: 10px 10px 0 0;
   }
`;

export const SearchArea = styled.div`
   display: flex;
   margin-bottom: 1rem;

   input {
      padding: 1rem;
      width: 80%;
      background: whitesmoke;
      border: none;
      border-radius: 10px 0px 0px 10px;
   }

   button {
      background: #3578e5;
      width: 20%;
      border: none;
      border-radius: 0px 10px 10px 0px;
   }
`;

export const MileStoneWrapper = styled.div`
   background: #eeeeeebd;
   margin-bottom: 1.5rem;
   margin-top: -8px;
   padding: 1rem;
   max-width: 374px;

   h5 {
      margin: 10px 0px;
   }

   p {
      color: #6b6b6b;
      font-size: 13px;
   }

   button {
      margin-top: 13px;
      width: 100px;
      border: none;
      text-align: start;
      color: #3578e5;
      font-size: 12px;
      padding: 0px;
      font-weight: 500;
      cursor: pointer;
      background: none;
   }

   span {
      font-size: 13px;
      transform: translateY(10px);
   }

   div {
      display: flex;
      justify-content: space-between;
   }
`;

export const NewsLetterContainer = styled.div`
   margin: 100px 0;
`;

export const NewsLetterMain = styled.div`
   background: #000;
   padding: 60px;
   text-align: center;
   color: #fff;
   h3 {
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 125% */
   }

   p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 177.778% */
      letter-spacing: 0.36px;
      margin-bottom: 40px;
   }

   ${responsive("$xsmall")`
      h3 {
         font-size: 24px;
      }
   
      p {
         font-size: 14px;
      }
      padding:20px;
  `}
`;

export const CenterInputContainer = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
`;

export const NewsInputContainer = styled.div`
   display: flex;
   width: 40%;
   ${responsive("$xsmall")`
     width: 100% !important;
    `}
`;

export const NewsInput = styled.input`
   padding: 10px;
   border: 1px solid #ccc;
   border-top-left-radius: 8px;
   border-bottom-left-radius: 8px;
   font-size: 14px;
   width: 60%;
`;

export const SubmitButton = styled.button`
   width: 40%;
   padding: 10.9px;
   background-color: #46d3c2;
   border: none;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
   cursor: pointer;
   color: #000;
   font-size: 16px;
   font-weight: 500;
   line-height: 24px; /* 150% */
   letter-spacing: 0.5px;
`;
