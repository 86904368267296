import { ChangeEvent, forwardRef } from "react";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import { useDebouncedCallback } from "use-debounce";
import { IInputProps } from "components/input/i-input";
import {
   EndAdornmentWrapperDiv,
   LandingInputFieldWrapperDiv,
   LandingInputField,
} from "components/input/input.style";
import { LandingSearchWrapper } from "./Search.style";

const LandingPageSearch = forwardRef<HTMLInputElement, IInputProps>(
   (
      {
         id,
         name,
         type = "text",
         value,
         fullWidth,
         className,
         onChange,
         startAdornment,
         isMobile,
         setShowSearch,
         handleSearch,
         placeholder,
         isLeft,
         searchVal,
         setSearchVal,
         ...remainingProps
      },
      ref
   ) => {
      // const [searchVal, setSearchVal] = useState("");

      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
         const { value } = event.target;
         debounced(value);
         setSearchVal && setSearchVal(() => value);
      };

      const debounced = useDebouncedCallback(
         // function
         (searchValue) => {
            if (searchValue.length > 0) return handleSearch && handleSearch(searchVal);
            setShowSearch && setShowSearch(false);
         },
         // delay in ms
         600
      );
      return (
         <LandingSearchWrapper>
            <LandingInputFieldWrapperDiv>
               <LandingInputField
                  id={id}
                  ref={ref}
                  name={name}
                  type={type}
                  value={value}
                  onChange={handleChange}
                  startAdornment
                  isMobile={isMobile}
                  placeholder={placeholder || "Search"}
                  {...remainingProps}
               />
               <EndAdornmentWrapperDiv>
                  <SearchOutlined fontSize="large" style={{ color: "#6D7A98" }} />
               </EndAdornmentWrapperDiv>
            </LandingInputFieldWrapperDiv>
         </LandingSearchWrapper>
      );
   }
);
export default LandingPageSearch;
