/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from "react";
import axios from "axios";
import commafy from "commafy";
import { motion } from "framer-motion";
import { GetServerSideProps } from "next";
import router from "next/router";
import { Button } from "components/button";
import LandingPageSearch from "components/search/LandingPageSearch";
import { FilterContainer, FilterWrapper } from "components/search/Search.style";
import { SelectInput } from "components/select";
import { AppContainer } from "container/app-container";
import {
   DiscountedSchools,
   EduskoInTheNews,
   FeaturedSchools,
   VideosDontLie,
} from "container/landing-page";
import { Articles } from "container/landing-page/articles";
import { HomePageAdvert } from "container/landing-page/home-page-advert";
import { SecondHomePageAdvert } from "container/landing-page/homepage-advert";
// import { LandingEducationFinance } from "container/landing-page/landing-eduction-finance";
import { UpdatedAdvertBanners } from "container/landing-page/new-advert-banner";
// import { SchoolChoice } from "container/landing-page/school-choice";
// import { UpdatedStudyAboard } from "container/landing-page/updated-study-abroad";
import NewsLetter from "container/landing-page/news-letter";
import { UpdatedTestimonialCards } from "container/landing-page/updated-testimonial-cards";
import { NonDashboardWrapper } from "container/non-dashboard-wrapper";
// import { useFilterToggle } from "context/school-filters/toggle-filter";
import { unSecuredBlogs } from "services/blog";
import { fetchSchools, getSchoolsStatsTotal, searchSchoolsAutosuggest } from "services/school";
import { fetchAllTestimonials } from "services/testimonial";
import {
   MainContainer,
   NonBannerContainer,
   NewBannerContainer,
   FlexStats,
   StatsBox,
   FlexInfo,
   InfoBox,
   SearchResultContainer,
   CenterSearchResult,
   SearchMainContainer,
} from "styles/index/index.style";
import { variants00, variants01 } from "utils/animation-variants";
// import { getSession } from "utils/auth";
import { CountriesList } from "utils/countries-list";
import { paramsObjectToQueryString } from "utils/generic-utils";
// import { schoolCategory, schoolType } from "utils/schoolFormOptions";

export default function Home({
   blogs,
   featuredSchools,
   testimonials,
   discountedSchools,
   schoolStats,
}) {
   const [currentFilters, setCurrentFilters] = useState({});
   const [currentStates, setCurrentStates] = useState<any[]>([]);
   const [searchVal, setSearchVal] = useState<string>("");
   const [searchResult, setSearchResult] = useState<any>([]);
   const [isSearch, setIsSearch] = useState<boolean>(false);

   const findSchools = async (val) => {
      setSearchVal(val); // Update search value
      if (val === "") {
         setIsSearch(false);
         setSearchResult([]); // Clear search results
      } else {
         fetchSearchResults(val);
      }
   };

   // Function to fetch search results
   const fetchSearchResults = async (val) => {
      try {
         const response: any = await searchSchoolsAutosuggest(val);
         const {
            result: {
               data: { schools },
            },
         } = response;
         setSearchResult(schools);
         setIsSearch(true);
      } catch (err) {
         setIsSearch(false);
         return Promise.reject("error");
      }
   };

   const checkLocation = (data) => {
      switch (true) {
         case data === "Ghana":
            return router.push("/ghana");
         case data === "Kenya":
            return router.push("/kenya");
         default:
            return;
      }
   };

   const routeUser = async () => {
      const res = await axios.get(
         "https://api.geoapify.com/v1/ipinfo?&apiKey=9519d52007524b0bb17f626c9364422f"
      );
      const location = res.data.country.name;

      checkLocation(location);
   };

   useEffect(() => {
      routeUser();
   }, []);

   useEffect(() => {
      findSchools(searchVal);
   }, [isSearch, searchVal]);

   function handleCurrentStates(countryName) {
      setCurrentStates([]);
      const currentState = CountriesList.find(
         (country) => country.name.toLowerCase() === countryName
      )?.states.map((el) => ({ value: el.name.toLowerCase(), label: el.name }));
      setCurrentStates(currentState as any[]);
   }

   function handleChange(e, type: string) {
      if (type === "tuition") {
         setCurrentFilters({ ...currentFilters, min: e?.value?.min, max: e?.value?.max });
      } else {
         setCurrentFilters({ ...currentFilters, [type]: e?.value });
      }
   }

   async function handleSearch() {
      router.push({
         pathname: "/school/search",
         query: paramsObjectToQueryString(currentFilters).slice(1),
      });
   }

   const pageStats = [
      {
         num: commafy(schoolStats?.totalEnquiry),
         label: "Students enrolled",
      },
      {
         num: commafy(schoolStats?.totalSchools),
         label: "Schools onboarded",
      },
      {
         num: "500k",
         label: "Community of parents",
      },
   ];

   const schoolInfo = [
      {
         img: "/landing-page/enroll.png",
         title: "Enroll your Kids",
         description:
            "Let’s help match your child with the right k-12 private school. More than 100,000 parents used our platform to make informed school choices last year.",
      },
      {
         img: "/landing-page/edufinance.png",
         title: "Finance their Education",
         description:
            "In our quest for equitable access to quality education in Africa, we have financed tuition for hundreds of kids through our lending partners.",
      },
      {
         img: "/landing-page/edcommerce.png",
         title: "Buy Cheaper, Quicker",
         description:
            "We launched Edcommerce, a platform that allows you to buy learning resources cheaper and quicker from top sellers and manufacturers. We’re also providing low interest asset financing for you.",
      },
   ];

   const tuitionOptions = [
      {
         value: { min: 0, max: 100000 },
         label: "₦0 -  ₦100,000",
      },
      { value: { min: 100001, max: 200000 }, label: "₦100,001 - ₦200,000" },
      { value: { min: 200001, max: 300000 }, label: "₦200,001 - ₦300,000" },
      { value: { min: 300001, max: 400000 }, label: "₦300,001 - ₦400,000" },
      { value: { min: 400001, max: 500000 }, label: "₦400,001 - ₦500,000" },
      { value: { min: 500001, max: 1000000 }, label: "₦500,001 - ₦1,000,000" },
      { value: { min: 1000001, max: 5000000 }, label: "₦1,000,000  + " },
   ];

   const ratingOptions = [
      { value: 1, label: "⭐ Interesting" },
      { value: 2, label: "⭐⭐ Cool" },
      { value: 3, label: "⭐⭐⭐ Good" },
      { value: 4, label: "⭐⭐⭐⭐ Great" },
      { value: 5, label: "⭐⭐⭐⭐⭐ Excellent" },
   ];

   return (
      <AppContainer
         title="Education Marketplace for Best Schools, Parents, Students, Lenders & More"
         slug={typeof window !== "undefined" ? window.location.href : ""}
         description="Welcome to Africa's largest education marketplace for best schools, parents, students, vendors, lenders and many more"
         image="/activated/seo-img.png"
      >
         <NonDashboardWrapper>
            <NewBannerContainer>
               <motion.h1 variants={variants00} initial="hidden" animate="show">
                  Your wards are a few clicks
               </motion.h1>
               <motion.h1 variants={variants01} initial="hidden" animate="show">
                  away from their dream school!
               </motion.h1>
               <SearchMainContainer>
                  <div style={{ marginTop: "50px" }}>
                     <LandingPageSearch
                        handleSearch={(val) => findSchools(val)}
                        setShowSearch={setIsSearch}
                        searchVal={searchVal}
                        setSearchVal={setSearchVal}
                        id="landing-page"
                        placeholder="Search schools, cities, categories etc"
                     />
                  </div>
                  {isSearch && searchResult.length > 0 && (
                     <CenterSearchResult>
                        <SearchResultContainer>
                           <div className="result-main">
                              {searchResult?.map((item, idx) => (
                                 <div key={idx} className="result">
                                    <p onClick={() => router.push(`/school/${item?.slug}`)}>
                                       {item?.name}
                                    </p>
                                 </div>
                              ))}
                           </div>
                        </SearchResultContainer>
                     </CenterSearchResult>
                  )}
               </SearchMainContainer>

               <FilterWrapper>
                  <div className="main-container">
                     <FilterContainer>
                        <div className="start">
                           <SelectInput
                              placeholder="Tuition per annum"
                              options={tuitionOptions}
                              onChange={(e: any) => handleChange(e, "tuition")}
                           />
                        </div>
                        <div className="end">
                           <SelectInput
                              placeholder="Rating"
                              options={ratingOptions}
                              onChange={(e: any) => handleChange(e, "rating")}
                           />
                        </div>
                     </FilterContainer>
                     <FilterContainer>
                        <div className="start">
                           <SelectInput
                              placeholder="Country"
                              options={CountriesList.map((c) => ({
                                 label: c.name,
                                 value: c.name.toLowerCase(),
                              }))}
                              onChange={(val: any) => {
                                 handleChange(val, "country");
                                 handleCurrentStates(val?.value);
                              }}
                           />
                        </div>
                        <div className="end">
                           <SelectInput
                              placeholder="State"
                              options={currentStates}
                              onChange={(e) => handleChange(e, "state")}
                              key={currentStates[0]?.value}
                           />
                        </div>
                     </FilterContainer>
                     <div className="landing-search-btn">
                        <Button
                           disabled={Object.keys(currentFilters).length === 0}
                           onClick={handleSearch}
                           className="find-school"
                           variant="success"
                        >
                           Find School
                        </Button>
                     </div>
                  </div>
               </FilterWrapper>
            </NewBannerContainer>

            <FlexStats>
               {pageStats?.map((stats, idx) => (
                  <StatsBox
                     small={idx === 1}
                     alignText={idx === 2 ? "left" : idx === 0 ? "right" : "center"}
                     key={idx}
                  >
                     <h2 className="num">{stats?.num}</h2>
                     <p className="label">{stats?.label}</p>
                  </StatsBox>
               ))}
            </FlexStats>

            <FlexInfo>
               {schoolInfo?.map((info, idx) => (
                  <InfoBox small={idx === 1} key={idx}>
                     <div className="flex-header">
                        <img className="info-image" src={info?.img} alt={`info/${info?.title}`} />
                        <div className="info-section">
                           <h4 className="info-header">{info?.title}</h4>
                           <p className="description">{info?.description}</p>
                           <a
                              href={
                                 idx === 0
                                    ? "/signup"
                                    : idx === 1
                                    ? "/education-finance"
                                    : "/edcommerce"
                              }
                           >
                              <Button
                                 onClick={() => {
                                    idx === 0
                                       ? router.push("/signup")
                                       : idx === 1
                                       ? router.push("/education-finance")
                                       : router.push("/edcommerce");
                                 }}
                                 className="info-btn"
                                 variant="outline-white"
                              >
                                 Learn more
                              </Button>
                           </a>
                        </div>
                     </div>
                  </InfoBox>
               ))}
            </FlexInfo>

            <NonBannerContainer>
               <MainContainer>
                  <FeaturedSchools schools={featuredSchools} headingText="Featured Schools" />
                  <DiscountedSchools discountedSchools={discountedSchools} />
                  <UpdatedAdvertBanners headingText="Explore more options" />
                  <HomePageAdvert />
               </MainContainer>
            </NonBannerContainer>
            <MainContainer>
               <UpdatedTestimonialCards testimonials={testimonials} />
            </MainContainer>
            <MainContainer>
               <VideosDontLie />
               <SecondHomePageAdvert />
            </MainContainer>
            <Articles header="Articles" isSeeMore={true} blogData={blogs} />
            <MainContainer>
               <EduskoInTheNews />
            </MainContainer>
            <NewsLetter />
         </NonDashboardWrapper>
      </AppContainer>
   );
}

export const getServerSideProps: GetServerSideProps = async () => {
   const getBlogs = async () => {
      try {
         const res = await unSecuredBlogs({});
         return res;
      } catch (error) {
         return null;
      }
   };

   const getFeaturedSchools = async () => {
      try {
         const res = await fetchSchools({ isFeatured: "yes", limit: 20, country: "nigeria" });
         return res;
      } catch (error) {
         return null;
      }
   };

   const getAllSchools = async () => {
      try {
         const res = await getSchoolsStatsTotal();
         return res;
      } catch (error) {
         return null;
      }
   };

   const getDiscountedSchools = async () => {
      try {
         const res = await fetchSchools({ hasDiscount: "yes", limit: 20, country: "nigeria" });
         return res;
      } catch (error) {
         return null;
      }
   };

   const getTestimonials = async () => {
      try {
         const res = await fetchAllTestimonials();
         return res;
      } catch (error) {
         return null;
      }
   };

   const values = await Promise.allSettled([
      getBlogs(),
      getFeaturedSchools(),
      getDiscountedSchools(),
      getTestimonials(),
      getAllSchools(),
   ]);

   const blogResponse = (values[0] as any)?.value;
   const featuredSchoolsResponse = (values[1] as any)?.value;
   const discountedSchoolsResponse = (values[2] as any)?.value;
   const testimonialsData = (values[3] as any)?.value;
   const schoolStatsData = (values[4] as any)?.value;

   return {
      props: {
         featuredSchools: (featuredSchoolsResponse as any)?.result?.data?.schools || null,
         discountedSchools: (discountedSchoolsResponse as any)?.result?.data?.schools || null,
         testimonials: (testimonialsData as any)?.result?.data.testimonials || null,
         blogs: (blogResponse as any)?.data?.data?.blogPosts || null,
         schoolStats: (schoolStatsData as any)?.data?.data || null,
      },
   };
};
